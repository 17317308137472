'use client';

import * as React from 'react';
import Image from 'next/image';
import { cn, MediaValue, AlignedTextValue } from '@av/utils';
import { Inner } from '../lib/setHtml';

const checkIfNumberExists = (value: string) => {
  return value && !isNaN(parseInt(value)) && !isNaN(parseFloat(value));
};

const ImageBlock = ({
  image,
  alt,
  width,
  height,
  radius,
  align,
  alignedText,
}: {
  image: MediaValue;
  alignedText: AlignedTextValue;
  alt: string;
  width: string;
  height: string;
  radius: string;
  align: 'left' | 'right' | 'center';
}) => {
  console.log({ image, alt, width, height, radius, align });
  if (!image?.url) return null;

  return (
    <div
      className={cn(
        'flex',
        alignedText.align !== 'none' && 'gap-4',
        alignedText.align === 'left' && 'flex-row-reverse'
      )}
    >
      <Image
        src={image.url}
        height={parseInt(height) || image.height || 0}
        width={parseInt(width) || image.width || 0}
        alt={alt || ''}
        className={cn(
          'object-cover object-top',
          align === 'right' && 'ml-auto',
          align === 'center' && 'mx-auto'
        )}
        style={{
          borderRadius: checkIfNumberExists(radius) ? radius : undefined,
          height: checkIfNumberExists(height) ? height : undefined,
          width: checkIfNumberExists(width) ? width : 'auto',
        }}
      />
      {alignedText.align !== 'none' && <Inner className="flex-1" html={alignedText.text} />}
    </div>
  );
};

export default ImageBlock;
